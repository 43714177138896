<template>
  <div id="workspace-builder">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title>
        <template v-if="mode === 'NEW'">Create Workspace</template>
        <template v-if="mode === 'EDIT'">Edit Workspace</template>
      </template>

      <template #description>
        <template v-if="mode === 'NEW'">
          Feel free to skip the optional fields and fill them later
        </template>
        <template v-if="mode === 'EDIT'">
          Some of the fields or non-editable
        </template>
      </template>

      <!-- ... -->

      <!-- action bar -->

      <ActionBar
        v-model="tab"
        :is-loading="isLoading"
        @save="mode === 'EDIT' ? updateWorkspace() : createWorkspace()"
      />

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar height="calc(100vh - 126px)">
        <div class="row">
          <ValidationObserver ref="form">
            <div class="form">
              <WorkspaceDetails
                ref="WORKSPACE_DETAILS"
                :mode="mode"
                :name.sync="name"
                :color.sync="color"
                :description.sync="description"
                :users.sync="users"
                :is-active="tab === 'WORKSPACE_DETAILS'"
                @click="tab = 'WORKSPACE_DETAILS'"
              />
            </div>
          </ValidationObserver>
        </div>
      </BaseScrollbar>

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import { workspace } from "@/api/factory.js";

import { ValidationObserver } from "vee-validate";
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import ActionBar from "./components/ActionBar.vue";
import WorkspaceDetails from "./components/WorkspaceDetails.vue";

export default {
  name: "WorkspaceBuilder",

  components: {
    ItemBuilder,
    ValidationObserver,
    ActionBar,
    WorkspaceDetails,
  },

  props: {
    workspaceId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "WORKSPACE_DETAILS",
      isLoading: false,

      // workspaceDetails

      name: "",
      description: "",
      color: "",
      users: [],
    };
  },

  watch: {
    workspaceId: {
      immediate: true,
      handler() {
        if (this.workspaceId) {
          this.mode = "EDIT";
          this.getWorkspace();
        }
      },
    },
  },

  methods: {
    closeBuilder() {
      this.$router.back();
    },

    async createWorkspace() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        name: this.name,
        description: this.description,
        color: this.color,
        userId_Array: this.users,
      };

      const { error } = await workspace.createWorkspace(payload);

      if (error) {
        this.$alert.error(error);
        this.isLoading = false;
        return;
      }

      this.closeBuilder();
      this.$alert.success("New workspace created");
    },

    async getWorkspace() {
      const { error, payload } = await workspace.getWorkspace(this.workspaceId);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.name = payload.name;
      this.description = payload.description;
      this.color = payload.color;
      this.users = payload.userId_Array;
    },

    async updateWorkspace() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        name: this.name,
        description: this.description,
        color: this.color,
        userId_Array: this.users,
      };

      const { error } = await workspace.updateWorkspace(
        this.workspaceId,
        payload
      );

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.closeBuilder();
      this.$alert.success("Workspace updated successfully");
    },
  },
};
</script>

<style lang="scss" scoped>
#workspace-builder {
  height: 100vh;
}
</style>
